import { types } from "../types/types";

const initialState = {
  aniosEscolares:[],
  anioEscolarActivo:'',
  periodosEscolares:[],
  periodoEscolarActivo:'',
  cursos:[],
  cursoActivo:'',
  asistencias:[],
  asistenciaActiva:'',
  asistenciaEstudiantes:[],
  asistenciaEstudianteActivo:'',
  asignaturas:[],
  asignaturaActiva:'',
  clasesAsignatura:[],
  claseAsignaturaActiva:'',
  calificacionesAsignatura:[],
  calificacionAsignaturaActiva:'',
  calificacionesDetalleAsignatura:[],
  talleres:[],
  tallerActivo:'',
  clasesTaller:[],
  claseTallerActiva:'',
  calificacionesTaller:[],
  calificacionTallerActiva:'',
  calificacionDetallesTaller:[],
};

export const libroDigitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearAnioEscolarOk:
      return {
        ...state,
        aniosEscolares:[ ...state.aniosEscolares, action.payload ]
      }
    case types.obtenerAniosEscolaresOk:
      return {
        ...state,
        aniosEscolares: action.payload
      }
    case types.actualizarAnioEscolarOk:
      return {
        ...state,
        aniosEscolares: state.aniosEscolares.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
      case types.activarAnioEscolar:
        return {
          ...state,
          anioEscolarActivo: action.payload
        }
      case types.limpiarAniosEscolares:
        return {
          ...state,
          aniosEscolares: []
        }
      // periodosEscolares
      case types.crearPeriodoEscolarOk:
        return {
          ...state,
          periodosEscolares:[ ...state.periodosEscolares, action.payload ]
        }
      case types.obtenerPeriodosEscolaresOk:
        return {
          ...state,
          periodosEscolares: action.payload
        }
      case types.actualizarPeriodoEscolarOk:
        return {
          ...state,
          periodosEscolares: state.periodosEscolares.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarPeriodoEscolar:
        return {
          ...state,
          periodoEscolarActivo: action.payload
        }
      case types.limpiarPeriodosEscolares:
        return {
          ...state,
          periodosEscolares: []
        }
      //  cursos
      case types.crearCursoOk:
        return {
          ...state,
          cursos:[ ...state.cursos, action.payload ]
        }
      case types.obtenerCursosOk:
        return {
          ...state,
          cursos: action.payload
        }
      case types.actualizarCursoOk:
        return {
          ...state,
          cursos: state.cursos.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCurso:
        return {
          ...state,
          cursoActivo: action.payload
        }
      case types.limpiarCursos:
        return {
          ...state,
          cursos: []
        }
        // asistencias
      case types.crearAsistenciaOk:
        return {
          ...state,
          asistencias:[ action.payload, ...state.asistencias ]
        }
      case types.obtenerAsistenciasOk:
        return {
          ...state,
          asistencias: action.payload
        }
      case types.actualizarAsistenciaOk:
        return {
          ...state,
          asistencias: state.asistencias.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarAsistencia:
        return {
          ...state,
          asistenciaActiva: action.payload
        }
      case types.limpiarAsistencias:
        return {
          ...state,
          asistencias: []
        }
        // asistencias estudiante
      case types.crearAsistenciaEstudianteOk:
        return {
          ...state,
          asistenciaEstudiantes:[ ...state.asistenciaEstudiantes, action.payload ]
        }
      case types.obtenerAsistenciaEstudiantesOk:
        return {
          ...state,
          asistenciaEstudiantes: action.payload
        }
      case types.actualizarAsistenciaEstudianteOk:
        return {
          ...state,
          asistenciaEstudiantes: state.asistenciaEstudiantes.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarAsistenciaEstudiante:
        return {
          ...state,
          asistenciaEstudianteActiva: action.payload
        }
      case types.limpiarAsistenciaEstudiantes:
        return {
          ...state,
          asistenciaEstudiantes: []
        }

      //  asignaturas
      case types.crearAsignaturaOk:
        return {
          ...state,
          asignaturas:[ ...state.asignaturas, action.payload ]
        }
      case types.obtenerAsignaturasOk:
        return {
          ...state,
          asignaturas: action.payload
        }
      case types.actualizarAsignaturaOk:
        return {
          ...state,
          asignaturas: state.asignaturas.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarAsignatura:
        return {
          ...state,
          asignaturaActiva: action.payload
        }
      case types.limpiarAsignaturas:
        return {
          ...state,
          asignaturas: []
        }
        // asignatura clases
      case types.crearClaseAsignaturaOk:
        return {
          ...state,
          clasesAsignatura:[ action.payload, ...state.clasesAsignatura ]
        }
      case types.obtenerClasesAsignaturaOk:
        return {
          ...state,
          clasesAsignatura: action.payload
        }
      case types.actualizarClaseAsignaturaOk:
        return {
          ...state,
          clasesAsignatura: state.clasesAsignatura.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarClaseAsignatura:
        return {
          ...state,
          claseAsignaturaActiva: action.payload
        }
      case types.limpiarClasesAsignatura:
        return {
          ...state,
          clasesAsignatura: []
        }
        
      
        // asignatura calificacion
      case types.crearCalificacionAsignaturaOk:
        return {
          ...state,
          calificacionesAsignatura:[ ...state.calificacionesAsignatura, action.payload ]
        }
      case types.obtenerCalificacionesAsignaturaOk:
        return {
          ...state,
          calificacionesAsignatura: action.payload
        }
      case types.actualizarCalificacionAsignaturaOk:
        return {
          ...state,
          calificacionesAsignatura: state.calificacionesAsignatura.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCalificacionAsignatura:
        return {
          ...state,
          calificacionAsignaturaActiva: action.payload
        }
      case types.limpiarCalificacionesAsignatura:
        return {
          ...state,
          calificacionesAsignatura: []
        }
        
      
        // asignatura calificacion
      case types.crearCalificacionDetalleAsignaturaOk:
        return {
          ...state,
          calificacionesDetalleAsignatura:[ ...state.calificacionesDetalleAsignatura, action.payload ]
        }
      case types.obtenerCalificacionesDetalleAsignaturaOk:
        return {
          ...state,
          calificacionesDetalleAsignatura: action.payload
        }
      case types.actualizarCalificacionDetalleAsignaturaOk:
        return {
          ...state,
          calificacionesDetalleAsignatura: state.calificacionesDetalleAsignatura.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCalificacionDetalleAsignatura:
        return {
          ...state,
          calificacionAsignaturaActiva: action.payload
        }
      case types.limpiarCalificacionesDetalleAsignatura:
        return {
          ...state,
          calificacionesAsignatura: []
        }
        
      


      //  talleres
      case types.crearTallerOk:
        return {
          ...state,
          talleres:[ ...state.talleres, action.payload ]
        }
      case types.obtenerTalleresOk:
        return {
          ...state,
          talleres: action.payload
        }
      case types.actualizarTallerOk:
        return {
          ...state,
          talleres: state.talleres.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarTaller:
        return {
          ...state,
          tallerActivo: action.payload
        }
      case types.limpiarTalleres:
        return {
          ...state,
          talleres: []
        }
       // taller clases
       case types.crearClaseTallerOk:
        return {
          ...state,
          clasesTaller:[ ...state.clasesTaller, action.payload ]
        }
      case types.obtenerClasesTallerOk:
        return {
          ...state,
          clasesTaller: action.payload
        }
      case types.actualizarClaseTallerOk:
        return {
          ...state,
          clasesTaller: state.clasesTaller.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarClaseTaller:
        return {
          ...state,
          claseTallerActiva: action.payload
        }
      case types.limpiarClasesTaller:
        return {
          ...state,
          clasesTaller: []
        }
        
        // taller calificacion
      case types.crearCalificacionTallerOk:
        return {
          ...state,
          calificacionesTaller:[ ...state.calificacionesTaller, action.payload ]
        }
      case types.obtenerCalificacionesTallerOk:
        return {
          ...state,
          calificacionesTaller: action.payload
        }
      case types.actualizarCalificacionTallerOk:
        return {
          ...state,
          calificacionesTaller: state.calificacionesTaller.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCalificacionTaller:
        return {
          ...state,
          calificacionTallerActiva: action.payload
        }
      case types.limpiarCalificacionesTaller:
        return {
          ...state,
          calificacionesTaller: []
        }
      // taller calificacion detalle
      case types.crearCalificacionDetalleTallerOk:
        return {
          ...state,
          calificacionDetallesTaller:[ ...state.calificacionDetallesTaller, action.payload ]
        }
      case types.obtenerCalificacionDetallesTallerOk:
        return {
          ...state,
          calificacionDetallesTaller: action.payload
        }
      case types.actualizarCalificacionDetalleTallerOk:
        return {
          ...state,
          calificacionDetallesTaller: state.calificacionDetallesTaller.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCalificacionDetalleTaller:
        return {
          ...state,
          calificacionDetalleTallerActiva: action.payload
        }
      case types.limpiarCalificacionDetallesTaller:
        return {
          ...state,
          calificacionDetallesTaller: []
        }
        
    default:
      return state;
  }
};