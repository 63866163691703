import { types } from "../types/types";

const initialState = {
  //editado
  libroDigitalAsistenciaScreen:false,
  libroDigitalAsistenciaRegistroScreen:false,
  libroDigitalAsistenciaInformeScreen:false,
  libroDigitalFormAsistenciaRegistro:false,
  libroDigitalTablaAsistencias:false,
  libroDigitalInformeAsistenciaEstudiante:'false',
  libroDigitalInformeDetalleAsistenciaEstudiante:'false',

  //libroDigital router
  libroDigitalTablaAniosEscolares: true,
  libroDigitalTablaCursos: false,
  libroDigitalTablaEstudiantes: false,
  libroDigitalTablaAsistencias: false,
  libroDigitalTablaAsistenciaEstudiantes: false,
  libroDigitalTablaPeriodos: false,
  libroDigitalTablaAsignaturas: false,
  libroDigitalTablaClasesAsignatura: false,
  libroDigitalFormClaseAsignaturaNueva: false,
  libroDigitalFormClaseAsignaturaActualizar: false,
  libroDigitalTablaCalificacionesAsignatura: false,
  libroDigitalTablaTalleres: false,
  libroDigitalTablaClasesTaller: false,
  libroDigitalFormClaseTallerNueva: false,
  libroDigitalFormClaseTallerActualizar: false,
  libroDigitalTablaCalificacionesTaller: false,
  libroDigitalAnotacionScreen:false,
};

export const routerReducer = (state = initialState, action) => {
  switch (action.type) {
    // modulo asistencia
    case types.libroDigitalAsistenciaScreen:
      return {
        ...state,
        libroDigitalTablaCursos: false,
        libroDigitalAsistenciaScreen:true,
        libroDigitalAsistenciaRegistroScreen:false,
        libroDigitalAsistenciaInformeScreen:false,
      }
    case types.libroDigitalAsistenciaRegistroScreen:
      return {
        ...state,
        libroDigitalAsistenciaScreen:true,
        libroDigitalAsistenciaRegistroScreen:true,
        libroDigitalAsistenciaInformeScreen:false,
        libroDigitalTablaAsistencias:true,
        libroDigitalTablaAsistenciaEstudiantes:false,
      }
    case types.libroDigitalAsistenciaInformeScreen:
      return {
        ...state,
        libroDigitalAsistenciaScreen:true,
        libroDigitalAsistenciaRegistroScreen:false,
        libroDigitalAsistenciaInformeScreen:true,
        
        libroDigitalInformeAsistenciaEstudiante:'true',
        libroDigitalInformeDetalleAsistenciaEstudiante:'false',
      }
    case types.libroDigitalInformeDetalleAsistenciaEstudiante:
      return {
        ...state,
        libroDigitalAsistenciaScreen:true,
        libroDigitalAsistenciaRegistroScreen:false,
        libroDigitalAsistenciaInformeScreen:true,
        
        libroDigitalInformeAsistenciaEstudiante:'false',
        libroDigitalInformeDetalleAsistenciaEstudiante:'true',
      }
      case types.libroDigitalTablaAsistenciaEstudiantes:
        return {
          ...state,
          libroDigitalTablaAsistenciaEstudiantes: true,
          libroDigitalTablaAsistencias: false,
        }

      //antiguo
    case types.libroDigitalTablaAniosEscolares:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: true,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaCursos:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: true,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaAsignaturasCurso: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaEstudiantes:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaEstudiantes: true,
        libroDigitalTablaAsignaturasCurso: false,
        libroDigitalTablaAsignaturasCursoSegundo: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
         libroDigitalTablaTalleres: false,
        libroDigitalFormClaseTallerActualizar: false,

        libroDigitalAnotacionScreen:false,
      }
    case types.libroDigitalAnotacionScreen:
      return {
        ...state,
        libroDigitalTablaEstudiantes: false,
        libroDigitalAnotacionScreen:true,
      }
      
    case types.libroDigitalTablaAsignaturasCurso:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaAsignaturasCurso: true,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
       libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
         libroDigitalTablaTalleres: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaAsignaturasCursoSegundo:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaAsignaturasCurso: false,
        libroDigitalTablaAsignaturasCursoSegundo: true,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
       libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
         libroDigitalTablaTalleres: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    
    
    case types.libroDigitalTablaPeriodos:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: true,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaAsignaturas:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: true,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaCalificacionesAsignatura: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaClasesAsignatura:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: true,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalFormClaseAsignaturaNueva:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: true,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalFormClaseAsignaturaActualizar:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: true,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaCalificacionesAsignatura:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaCalificacionesAsignatura: true,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalFormClaseContenidoAsignaturaActualizar:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalFormClaseContenidoAsignaturaActualizar: true,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalTablaTalleres:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: true,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
        libroDigitalTablaCalificacionesTaller: false,
      }
    case types.libroDigitalTablaClasesTaller:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: true,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
        libroDigitalTablaCalificacionesAsignatura: false,
      }
    case types.libroDigitalFormClaseTallerNueva:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: true,
        libroDigitalFormClaseTallerActualizar: false,
      }
    case types.libroDigitalFormClaseTallerActualizar:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: true,
      }
    case types.libroDigitalTablaCalificacionesTaller:
      return {
        ...state,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalTablaCursos: false,
        libroDigitalTablaAsistencias: false,
        libroDigitalTablaAsistenciaEstudiantes: false,
        libroDigitalTablaPeriodos: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalTablaClasesAsignatura: false,
        libroDigitalFormClaseAsignaturaNueva: false,
        libroDigitalFormClaseAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalTablaClasesTaller: false,
        libroDigitalFormClaseTallerNueva: false,
        libroDigitalFormClaseTallerActualizar: false,
        libroDigitalTablaCalificacionesTaller: true,
      }
   
    default:
      return state;
  }
};