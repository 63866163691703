import { types } from "../types/types";

const initialState = {
  establecimientos:[],
  establecimientoActivo:'',
  categoriasNoticia:[],
  categoriaNoticiaActiva:'',
  noticias:[],
  noticiaActiva:'',
  informacion:{},
  informacionActiva:'',
};

export const establecimientoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearEstablecimientoOk:
      return {
        ...state,
        establecimientos:[ ...state.establecimientos, action.payload ]
      }
    case types.obtenerEstablecimientosOk:
      return {
        ...state,
        establecimientos: action.payload
      }
    case types.actualizarEstablecimientoOk:
      return {
        ...state,
        establecimientos: state.establecimientos.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
      case types.activarEstablecimiento:
        return {
          ...state,
          establecimientoActivo: action.payload
        }
      // categoria noticia
      case types.crearCategoriaNoticiaOk:
        return {
          ...state,
          categoriasNoticia:[ ...state.categoriasNoticia, action.payload ]
        }
      case types.obtenerCategoriasNoticiaOk:
        return {
          ...state,
          categoriasNoticia: action.payload
        }
      case types.actualizarCategoriaNoticiaOk:
        return {
          ...state,
          categoriasNoticia: state.categoriasNoticia.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCategoriaNoticia:
        return {
          ...state,
          categoriaNoticiaActiva: action.payload
        }
      //  noticia
      case types.crearNoticiaOk:
        return {
          ...state,
          noticias:[ ...state.noticias, action.payload ]
        }
      case types.obtenerNoticiasOk:
        return {
          ...state,
          noticias: action.payload
        }
      case types.actualizarNoticiaOk:
        return {
          ...state,
          noticias: state.noticias.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarNoticia:
        return {
          ...state,
          noticiaActiva: action.payload
        }
      //  informacion
      case types.crearEstablecimientoInformacionOk:
        return {
          ...state,
          informacion: action.payload
        }
      case types.obtenerEstablecimientoInformacionOk:
        return {
          ...state,
          informacion: action.payload
        }
      case types.actualizarEstablecimientoInformacionOk:
        return {
          ...state,
          informacion: action.payload
        }
      case types.activarEstablecimientoInformacion:
        return {
          ...state,
          noticiaActiva: action.payload
        }
      case types.limpiarEstablecimientoInformacion:
        return {
          ...state,
          informacion: {}
        }
    default:
      return state;
  }
};