import { types } from "../types/types";

const initialState = {
  file: false,
};

export const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    //admin 
    case types.startUploadingOk: 
      return{
        ...state,
        file: action.payload, 
      }
    case types.clearUploadFile: 
      return{
        ...state,
        file: action.payload, 
      }
    default:
      return state;
  }
};