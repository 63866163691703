//const baseUrl = 'http://localhost:5000/api/v1/cassjps-administrador';
const baseUrl = 'http://142.93.78.28:5000/api/v1/cassjps-administrador';

const fetchSinToken = ( endpoint, data, method='GET' ) => {
    const url = `${ baseUrl }/${ endpoint}`;
    if( method === 'GET' ) {
        return fetch( url );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json'
            },
            body:JSON.stringify( data )
        });
    }
};

const fetchConToken = ( endpoint, data, method='GET' ) => {

    const url = `${ baseUrl }/${ endpoint}`;
    const token = localStorage.getItem('token') || '';

    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'x-auth-token': token

            }
        } );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json',
                'x-auth-token': token
            },
            body:JSON.stringify( data )
        });
    }


};


const fetchConTokenFiltro = ( endpoint, filtro,  data, method='GET' ) => {
    const url = `${ baseUrl }/${ endpoint}`;
    const token = localStorage.getItem('token') || '';
    if(!filtro.establecimiento) filtro.establecimiento = ''; 
    if(!filtro.usuario) filtro.usuario = ''; 
    if(!filtro.estudiante) filtro.estudiante = ''; 
    if(!filtro.docente) filtro.docente = ''; 
    if(!filtro.anioEscolar) filtro.anioEscolar = '';
    if(!filtro.periodoEscolar) filtro.periodoEscolar = '';
    if(!filtro.curso) filtro.curso = '';
    if(!filtro.asignatura) filtro.asignatura = ''; 
    if(!filtro.taller) filtro.taller = '';
    if(!filtro.asistencia) filtro.asistencia = '';  
    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'establecimiento': filtro.establecimiento,
                'usuario': filtro.usuario,
                'estudiante': filtro.estudiante,
                'docente': filtro.docente,
                'anioEscolar': filtro.anioEscolar,
                'periodoEscolar': filtro.periodoEscolar,
                'curso': filtro.curso,
                'asignatura': filtro.asignatura,
                'taller': filtro.taller,
                'asistencia': filtro.asistencia,
                'x-auth-token': token

            }
        } );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json',
                'x-auth-token': token
            },
            body:JSON.stringify( data )
        });
    }
    

};
const fetchFiltro = ( endpoint, filtro,  data, method='GET' ) => {
    const url = `${ baseUrl }/${ endpoint}`;
    if(!filtro.usuario) filtro.usuario = ''; 
    if(!filtro.estudiante) filtro.estudiante = ''; 
    if(!filtro.docente) filtro.docente = ''; 
    if(!filtro.establecimiento) filtro.establecimiento = ''; 
    if(!filtro.anioEscolar) filtro.anioEscolar = '';
    if(!filtro.periodoEscolar) filtro.periodoEscolar = '';
    if(!filtro.curso) filtro.curso = '';
    if(!filtro.asignatura) filtro.asignatura = ''; 
    if(!filtro.taller) filtro.taller = ''; 
    if(!filtro.asistencia) filtro.asistencia = ''; 
    if( method === 'GET' ) {
        return fetch( url, {
            method,
            headers: {
                'establecimiento': filtro.establecimiento,
                'usuario': filtro.usuario,
                'estudiante': filtro.estudiante,
                'docente': filtro.docente,
                'anioEscolar': filtro.anioEscolar,
                'periodoEscolar': filtro.periodoEscolar,
                'curso': filtro.curso,
                'asignatura': filtro.asignatura,
                'taller': filtro.taller,
                'asistencia': filtro.asistencia,

            }
        } );
    } else {
        return fetch(url, {
            method,
            headers:{
                'Content-type': 'application/json',
            },
            body:JSON.stringify( data )
        });
    }
};

export {
    fetchSinToken,
    fetchConToken,
    fetchConTokenFiltro,
    fetchFiltro
};