import GatsbyLink from 'gatsby-link';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../action/auth';


import logo from '../images/logo.png'

export const Navbar = () => {
    const dispatch = useDispatch()
    const {  uid } = useSelector(state => state.auth);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <GatsbyLink to="/" className="navbar-brand">
                    <div>
                        <img src={logo} alt="logo" width={50} />
                        Colegio de Jóvenes y Adultos su Santidad Juan Pablo II
                    </div>
                </GatsbyLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    { !uid 
                        ?
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <GatsbyLink to="/" className="nav-link">Inicio </GatsbyLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://cassjps.cl" target="_blank" rel="noreferrer">Cassjps</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://otecjps.cl" target="_blank" rel="noreferrer">OtecJPS</a>
                            </li>
                        </ul>
                        :
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <GatsbyLink to="/" className="nav-link">Inicio </GatsbyLink>
                            </li>
                            {/* <li className="nav-item">
                                <GatsbyLink to="/administrador" className="nav-link">Administrador </GatsbyLink>
                            </li> */}
                            <li className="nav-item">
                                <GatsbyLink to="/libroDigital" className="nav-link">Libro Digital </GatsbyLink>
                            </li>
                            <li className="nav-item">
                                <GatsbyLink to="/estudiantes" className="nav-link">Estudiantes </GatsbyLink>
                            </li>
                            
                        </ul>
                    }
                    <form className="d-flex">
                        { !uid 
                            ? <GatsbyLink to="/login"><button className="btn btn-outline-success" type="submit" >Iniciar sesion</button> </GatsbyLink>
                            : <button className="btn btn-outline-danger" type="submit" onClick={() => dispatch(startLogout())}>Salir</button>
                        }
                    </form>
                </div>
            </div>
        </nav>
    )
}
