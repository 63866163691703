import { combineReducers } from 'redux';
import {routerReducer} from './routerReducer';
import {UsuarioReducer} from './UsuarioReducer';
import {authReducer} from './authReducer';
import { establecimientoReducer } from './establecimientoReducer';
import { fileReducer } from './fileReducer';
import { estudianteReducer } from './estudianteReducer';
import { libroDigitalReducer } from './libroDigitalReducer';


export const rootReducer = combineReducers({
    establecimiento: establecimientoReducer,
    libroDigital: libroDigitalReducer,
    estudiante: estudianteReducer,
    usuario: UsuarioReducer,
    file: fileReducer,
    auth: authReducer,
    router: routerReducer,
});