import { types } from "../types/types";

const initialState = {
  usuarios:[],
  usuarioActivo:'',
  credencialesUsuario:[],
  credencialUsuarioActiva:'',
  docentes:[]
};

export const UsuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearUsuarioOk:
      return {
        ...state,
        usuarios:[ ...state.usuarios, action.payload ]
      }
    case types.obtenerUsuariosOk:
      return {
        ...state,
        usuarios: action.payload
      }
    case types.obtenerDocentesOk:
      return {
        ...state,
        docentes: action.payload
      }
    case types.actualizarUsuarioOk:
      return {
        ...state,
        usuarios: state.usuarios.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarUsuario:
      return {
        ...state,
        usuarioActivo: action.payload
      }
      // credencial usuario
    case types.crearCredencialUsuarioOk:
      return {
        ...state,
        credencialesUsuario:[ ...state.credencialesUsuario, action.payload ]
      }
    case types.obtenerCredencialesUsuarioOk:
      return {
        ...state,
        credencialesUsuario: action.payload
      }
    case types.actualizarCredencialUsuarioOk:
      return {
        ...state,
        credencialesUsuario: state.credencialesUsuario.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarCredencialUsuario:
      return {
        ...state,
        credencialUsuarioActiva: action.payload
      }
      
    case types.limpiarCredencialesUsuario:
      return {
        ...state,
        credencialesUsuario: []
      }
      // informacion usuario
    case types.crearUsuarioInformacionOk:
      return {
        ...state,
        informacionUsuario: action.payload
      }
    case types.obtenerUsuarioInformacionOk:
      return {
        ...state,
        informacionUsuario: action.payload
      }
    case types.actualizarUsuarioInformacionOk:
      return {
        ...state,
        informacionUsuario: action.payload
      }
    case types.activarUsuarioInformacion:
      return {
        ...state,
        informacionUsuarioActiva: action.payload
      }
      
    case types.limpiarUsuarioInformacion:
      return {
        ...state,
        informacionUsuario: []
      }
      
    default:
      return state;
  }
};